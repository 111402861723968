import React, { ReactElement } from "react";
import dynamic from "next/dynamic";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import BestFloorCleaningMachinesWrapper from "@components/layouts/bestFloorCleaningMachinesWrapper";
import MainBanner from "@components/bestfloorcleaningmachines/mainBanner";

import { useDomainContext } from "@hooks/useDomainContext";

const FAQ = dynamic(
    () =>
        import(
            "@components/bestfloorcleaningmachines/floorCleaningMachineTypes"
        ),
);
const OffersList = dynamic(() => import("@components/shared/offersList"));

export default function BestFloorCleaningMachinesHome(): ReactElement {
    const { domain } = useDomainContext();
    const style = {
        background: "#fff",
        borderColor: "#e3e3e3",
        buttonBackground: "#3ea636",
        buttonForground: "#fff",
    };

    return (
        <BestFloorCleaningMachinesWrapper>
            <MainBanner />
            <section style={{ padding: "0 15px" }}>
                <OffersList offers={domain?.defaultOffers} style={style} />
            </section>
            <FAQ />
        </BestFloorCleaningMachinesWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
